<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header">
          <div class="col-12 title-header pl-4">
            <div @click="showModal()" class="back-btn mr-3">
              <img src="@/assets/images/userlevel/arrow_back.svg" alt="" />
            </div>
            {{ lbl['user-level-create-member-level'] }}
          </div>
        </div>
        <div class="user-body col-12">
          <div class="body-container">
            <div class="body-main">
              <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <StepProgress
                    class="ml-1"
                    :step="4"
                    :header="progressHeader"
                    :desc="progressDesc"
                  ></StepProgress>
                  <div class="content-main">
                    <div class="body-header">
                      {{ lbl['user-level-select-level-fill-details'] }}
                      <span class="fill-red">*</span>
                    </div>
                    <div class="badge-category">
                      <ul
                        class="badge-selector"
                        v-for="(item, index) in data"
                        :key="index"
                        :class="{ selected: selectBadge == index }"
                      >
                        <li @click="clickBadge(index, item)" class="badge-item">
                          <img
                            v-if="item.url"
                            class="badge-image"
                            :src="`${item.url}`"
                            alt=""
                          />
                          <h2 class="badge-desc">{{ item.name }}</h2>
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="data != undefined && data != null"
                      class="editer-container"
                    >
                      <div v-if="selectBadge != null">
                        <VueEditor
                          v-model="data[selectBadge].detail"
                          :editor-toolbar="customToolbar"
                          @selection-change="handleEditor"
                        />
                        <div v-if="isErrorDetail" class="error-warning mt-2">
                          ***
                          {{ lbl['user-level-please-fill-detail'] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="preview-main">
                    <div class="preview-header">
                      {{ lbl['user-level-preview'] }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="previewContainer">
                      <img
                        src="@/assets/images/userlevel/img_mobile.png"
                        class="img-mobile"
                        alt="img"
                      />
                      <div class="content-item">
                        <div class="box-item">
                          <div class="pb-2 pt-1 myprofile-title">
                            {{ lbl['user-level-point-myprofile'] }}
                          </div>
                          <div class="mainCard">
                            <div class="nameContainer">
                              <div class="level">
                                <img
                                  src="@/assets/images/userlevel/Star_Platinum.svg"
                                  alt="userlevel"
                                  width="30"
                                  height="30"
                                />
                              </div>
                              <div class="name">Thomas Shelby</div>
                            </div>
                            <div class="phone">08x-xxx-xxxx</div>
                            <div class="pointYouHave">
                              {{ lbl['user-level-point-name'] }}
                              <span class="point">2,000</span>
                              {{
                                dataUserLevel.levelBy == 1
                                  ? lbl['user-level-number-point']
                                  : lbl['user-level-number-baht']
                              }}
                            </div>
                            <div class="progressBarContainer">
                              <div class="progressBar">
                                <div class="pgbar"></div>
                              </div>
                              <div v-if="data.length == 1" class="level">
                                <img
                                  class="profile__image"
                                  src="@/assets/images/userlevel/Star_Platinum.svg"
                                  alt="Avatar"
                                  width="30"
                                  height="30"
                                />
                                <div class="icon-name">Standard</div>
                              </div>
                              <div v-else class="level">
                                <img
                                  class="profile__image"
                                  src="@/assets/images/userlevel/Star_Gold.svg"
                                  alt="Avatar"
                                  width="30"
                                  height="30"
                                />
                                <div class="icon-name">Gold</div>
                              </div>
                            </div>
                            <div class="detail">
                              20
                              {{
                                dataUserLevel.levelBy == 1
                                  ? lbl['user-level-point-expired']
                                  : lbl['user-level-baht-expired']
                              }}
                              30-04-2022
                            </div>
                          </div>
                          <div class="preview-body">
                            <div class="preview-scorll">
                              <div class="preview-category">
                                <ul
                                  class="preview-selector"
                                  v-for="(item, index) in data"
                                  :key="index"
                                  :class="{ selected: selectPreview == index }"
                                >
                                  <li
                                    @click="clickPreview(index)"
                                    class="badge-item"
                                  >
                                    <img
                                      v-if="item.url"
                                      class="badge-image"
                                      :src="`${item.url}`"
                                      alt=""
                                    />
                                    <h2 class="badge-desc">{{ item.name }}</h2>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div
                              v-if="data != undefined && data != null"
                              class="preview-detail"
                            >
                              <span>{{
                                lbl['user-level-preview-details']
                              }}</span>
                              <div
                                v-html="
                                  selectDataPreview(selectPreview, 'data')
                                "
                                class="inside-preview mb-0"
                                :class="{
                                  seemore: selectPreview === clickSeemore,
                                }"
                              ></div>
                              <a
                                v-if="
                                  selectDataPreview(selectPreview, 'data') !=
                                    '' &&
                                  selectDataPreview(selectPreview, 'data') !=
                                    null &&
                                  selectPreview != clickSeemore
                                "
                                href=""
                                class="btn-seemore"
                                @click="handleSeemore"
                                >{{ lbl['user-level-preview-see-more'] }}</a
                              >
                            </div>
                            <div class="wrapper-other">
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{ lbl['user-level-point-myprofile'] }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{ lbl['user-level-preview-address-name'] }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{ lbl['user-level-preview-history-name'] }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                              <div class="row py-1 bg-white">
                                <div class="col-8 clickto">
                                  {{
                                    lbl[
                                      'user-level-preview-privacy-consent-name'
                                    ]
                                  }}
                                </div>
                                <div class="col-4 icon-click">
                                  <a-icon type="right" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-footer col-12 between">
          <div @click="back()" class="previous-btn">
            <img
              class="mr-2"
              src="@/assets/images/userlevel/arrow_back.svg"
              alt=""
            />
            {{ lbl['user-level-previous-button-step-3'] }}
          </div>
          <b-button
            variant="warning"
            class="userlevel-btn"
            :disabled="isDisableNext"
            @click="next()"
          >
            {{ lbl['user-level-button-next'] }}
            <img src="@/assets/images/userlevel/arrow_forward.svg" alt="" />
          </b-button>
        </div>
      </div>
    </div>
    <ModalCancle />
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import StepProgress from '@/module/UserProfile/components/StepProgress.vue'
import ModalCancle from '@/module/UserProfile/modal/CancleModal.vue'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'StepFour',
  components: {
    Header,
    StepProgress,
    ModalCancle,
    VueEditor,
  },
  mixins: [Mixin],
  data: function () {
    return {
      currenttitle: null,
      current: null,
      parent: null,
      progressHeader: '',
      progressDesc: '',
      selectBadge: null,
      selectPreview: 0,
      data: [],
      isDisableNext: true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      clickSeemore: null,
      dataUserLevel: {},
      isErrorDetail: false,
    }
  },
  created() {
    this.handleFooter(true)
    this.handleLoading(false)
    Account.bzbsAnalyticTracking(
      'member_level_step4',
      'member_level',
      'view_member_level_step4',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.currenttitle = this.lbl['user-level-member-level']
      this.current = this.lbl['user-level-create-member-level']
      this.progressHeader = this.lbl['user-level-member-level-information']
      this.progressDesc = this.lbl['user-level-member-level-information-desc']

      this.dataUserLevel = JSON.parse(localStorage.getItem('dataUserLevel'))
      if (this.dataUserLevel != undefined) {
        if (this.dataUserLevel.memberLevel != undefined) {
          this.data = Object.entries(this.dataUserLevel.memberLevel).map(
            arr => {
              return arr[1]
            },
          )
        }

        this.handleEditor()
      } else {
        this.$router.push({
          name: 'CreateUserLevel',
        })
      }

      this.funcAddDetail()
    },
    showModal() {
      Account.bzbsAnalyticTracking(
        'member_level_step4',
        'member_level',
        'click_member_level_step4_exit',
        'on click',
      )
      this.$bvModal.show('modalCancle')
    },
    clickBadge(index) {
      console.log('clickBadge : ', index)
      this.selectBadge = index
      this.data[index].index = index
    },
    clickPreview(index) {
      this.selectPreview = index
    },
    handleSeemore(e) {
      e.preventDefault()
      this.clickSeemore = this.selectPreview
    },
    funcAddDetail() {
      var data = _.find(this.data, item => {
        if (item.detail != undefined) return item
      })

      if (data == undefined || data == null) {
        for (let i = 0; i < this.data.length; i++) {
          if (i != this.data.length - 1) {
            let membership = this.lbl[
              'user-level-your-membership-name-tier'
            ].replace('{0}', this.data[i].name)
            let collect
            if (this.dataUserLevel.levelBy == 1) {
              collect = this.lbl['user-level-level-collect-tier'].replace(
                '{0}',
                this.data[i + 1].start,
              )
            } else {
              collect = this.lbl[
                'user-level-level-collect-tier-with-spending'
              ].replace('{0}', this.data[i + 1].start)
            }

            this.data[i].detail = '<p>' + membership + ' ' + collect + '</p>'
          } else {
            let membership = this.lbl[
              'user-level-your-membership-name-tier'
            ].replace('{0}', this.data[i].name)

            this.data[i].detail = '<p>' + membership + '</p>'
          }
        }

        this.selectBadge = 0
      } else {
        for (let i = 0; i < this.data.length; i++) {
          if (i != this.data.length - 1) {
            if (
              this.data[i].detail == null ||
              this.data[i].detail == undefined
            ) {
              let membership = this.lbl[
                'user-level-your-membership-name-tier'
              ].replace('{0}', this.data[i].name)
              let collect
              if (this.dataUserLevel.levelBy == 1) {
                collect = this.lbl['user-level-level-collect-tier'].replace(
                  '{0}',
                  this.data[i + 1].start,
                )
              } else {
                collect = this.lbl[
                  'user-level-level-collect-tier-with-spending'
                ].replace('{0}', this.data[i + 1].start)
              }

              this.data[i].detail = '<p>' + membership + ' ' + collect + '</p>'
            }
          } else {
            if (
              this.data[i].detail == null ||
              this.data[i].detail == undefined
            ) {
              let membership = this.lbl[
                'user-level-your-membership-name-tier'
              ].replace('{0}', this.data[i].name)

              this.data[i].detail = '<p>' + membership + '</p>'
            }
          }
        }

        this.selectBadge = 0
      }

      this.isDisableNext = false
    },
    selectDataPreview(index, type) {
      if (type == 'data') {
        return this.data[index].detail
      } else {
        return this.data[index].index
      }
    },
    handleEditor() {
      this.clickSeemore = null
    },
    checkDetail() {
      this.isErrorDetail = false
      var data = _.filter(this.data, item => {
        if (
          item.detail != undefined &&
          item.detail != null &&
          item.detail != ''
        )
          return item
      })
      if (data.length == this.data.length) {
        return true
      } else {
        this.isErrorDetail = true
        return false
      }
    },
    next() {
      Account.bzbsAnalyticTracking(
        'member_level_step4',
        'member_level',
        'click_member_level_step4_next',
        'on click',
      )
      if (this.checkDetail()) {
        if (this.dataUserLevel.isEdit != undefined) {
          if (this.dataUserLevel.isEdit == true) {
            this.dataUserLevel.isUpdate = true
          }
        }
        this.dataUserLevel.memberLevel = this.data
        localStorage.setItem(
          'dataUserLevel',
          JSON.stringify(this.dataUserLevel),
        )
        this.$router.replace({
          name: 'PreviewLevel',
        })
      }
    },
    back() {
      Account.bzbsAnalyticTracking(
        'member_level_step4',
        'member_level',
        'click_member_level_step4_back',
        'on click',
      )
      if (this.dataUserLevel.isEdit != undefined) {
        if (this.dataUserLevel.isEdit == true) {
          this.dataUserLevel.isUpdate = true
        }
      }
      this.dataUserLevel.memberLevel = this.data
      localStorage.setItem('dataUserLevel', JSON.stringify(this.dataUserLevel))
      this.$router.replace({
        name: 'StepThree',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserProfile/UI/BaseStyle.scss';
ul,
li {
  padding: 0;
  margin: 0;
}
.user-body {
  width: 100%;
  .body-container {
    width: 100%;
    .body-main {
      margin: 0 25px;
      .content-main {
        .badge-category {
          display: flex;
          margin: 15px 0;
          overflow-y: auto;
          .badge-selector {
            display: flex;
            list-style: none;
            border: solid 1px #bdbdbd;
            background-color: #f5f5f5;
            border-radius: 40px;
            padding: 2px 8px;
            margin-right: 10px;
            cursor: pointer;
            &.selected {
              border: solid 2px #616161;
              background-color: #ffffff;
              box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
            }
            .badge-item {
              display: flex;
              align-items: center;
              justify-content: center;
              .badge-image {
                height: 25px;
                margin-right: 5px;
              }
              .badge-desc {
                font-size: 0.9rem;
                margin: 0;
              }
            }
          }
        }
        .editer-container {
          width: 95%;
        }

        .error-warning {
          width: 100%;
          color: #ff5252;
        }

        .fill-red {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.15px;
          text-align: left;
          color: #ff5252;
        }
      }
      .preview-main {
        .preview-header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 0.15px;
          text-align: left;
          color: #424242;
        }
      }
    }
  }
}
</style>
